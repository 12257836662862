import { snackController } from '@/components/snack-bar/snack-bar-controller'
import { getMysteryBoxesOwned } from '@/handlers/utils'
import { DecoratedPopoModel } from '@/models/decorated-popo-model'
import { localData } from '@/stores/local-data'
import { PopoStore, popoStore } from '@/stores/popo-store'
import { walletStore } from '@/stores/wallet-store'
import { map, orderBy, sortBy } from 'lodash'
import { action, computed, IReactionDisposer, observable, reaction, when } from 'mobx'
import { asyncAction } from 'mobx-utils'

export class ProfileNftViewModel {
  @observable decoratedPoPos?: DecoratedPopoModel = undefined
  @observable loaded = false
  @observable mysteryBoxOwned = [] as any
  @observable loadedMysteryBoxOwned = false

  // POPO
  @observable popoSorts = [
    { label: 'Highest ID', value: 'highest-id', field: 'assetId', order: 'desc' },
    { label: 'Lowest ID', value: 'lowest-id', field: 'assetId', order: 'asc' },
  ]
  @observable selectedPopoSort: any = this.popoSorts[0]

  @observable mysteryBoxSorts = [
    { label: 'Highest ID', value: 'highest-id', field: 'assetId', order: 'desc' },
    { label: 'Lowest ID', value: 'lowest-id', field: 'assetId', order: 'asc' },
  ]

  @observable selectedMysteryBoxSort: any = this.mysteryBoxSorts[0]

  // WEARABLE
  @observable wearableNftSorts = [
    { label: 'Highest ID', value: 'highest-id', field: 'assetId', order: 'desc' },
    { label: 'Lowest ID', value: 'lowest-id', field: 'assetId', order: 'asc' },
  ]
  @observable selectedWearableNftSort: any = this.wearableNftSorts[0]

  @observable wearableTypeFilters = [
    { label: 'All', value: 'all' },
    { label: 'Top NFT', value: 'top' },
    { label: 'Bottom NFT', value: 'bottom' },
    { label: 'Accessories 1', value: 'hair_accessory' },
    { label: 'Accessories 2', value: 'prop_accessory' },
  ]
  @observable selectedWearableTypeFilter: 'all' | 'top' | 'bottom' | 'hair_accessory' | 'prop_accessory' = this
    .wearableTypeFilters[0].value as any

  @observable wearableStatusFilters = [
    { label: 'All', value: 'all' },
    { label: 'Attached', value: 'actived' },
    { label: 'Available for attach', value: 'not-actived' },
  ]
  @observable selectedWearableStatusFilter = undefined

  private _disposers: IReactionDisposer[]

  constructor() {
    this._disposers = [
      reaction(
        () => walletStore.userProfile,
        async (userProfile) => {
          if (userProfile?._id) {
            await when(() => localData.getAccessToken())
            if (userProfile?.walletAddress) {
              this.fetchMysteryBoxOwned()
            }
          }
        },
        {
          fireImmediately: true,
        }
      ),
    ]
  }

  @asyncAction *fetchMysteryBoxOwned() {
    try {
      this.mysteryBoxOwned = yield getMysteryBoxesOwned(walletStore.account)
    } catch (e: any) {
      snackController.error(e.message || e.msg || e)
    } finally {
      this.loadedMysteryBoxOwned = true
    }
  }

  changePopoSort(value) {
    this.selectedPopoSort = this.popoSorts.find((item) => item.value === value)
  }
  changeMysteryBoxSort(value) {
    this.selectedMysteryBoxSort = this.mysteryBoxSorts.find((item) => item.value === value)
  }

  changeWearableTypeFilter(value) {
    this.selectedWearableTypeFilter = value
  }

  changeWearableStatusFilter(value) {
    this.selectedWearableStatusFilter = value
  }

  changeWearableNftSort(value) {
    this.selectedWearableNftSort = this.wearableNftSorts.find((item) => item.value === value)
  }

  @action detachSingleNft(nft) {
    popoStore.changeOpenPopoDetachSingleDialog(true, nft?.decoratedPopo?.id, nft?.id)
  }

  @computed get filteredDecoratedPopo() {
    return orderBy(popoStore.builtDecoratedPopos, [this.selectedPopoSort.field], [this.selectedPopoSort.order])
  }
  @computed get totalWearableNfts() {
    let wearableNftOwned = [...(popoStore.wearableNftOwned || [])]
    popoStore.decoratedPoPos?.map((decoratedPopo) => {
      const attachedIds = popoStore.getNftIds(decoratedPopo)
      wearableNftOwned = wearableNftOwned.map((nft) => {
        if (nft.id && attachedIds.includes(nft.id)) {
          return { ...nft, decoratedPopo, attached: true }
        }
        return nft
      })
    })
    return wearableNftOwned
  }

  @computed get wearableNftStats() {
    return {
      top: this.totalWearableNfts.filter((item) => item.type === 'top').length,
      bottom: this.totalWearableNfts.filter((item) => item.type === 'bottom').length,
      hair: this.totalWearableNfts.filter((item) => item.type === 'hair_accessory').length,
      prop: this.totalWearableNfts.filter((item) => item.type === 'prop_accessory').length,
    }
  }

  @computed get attachedWearableNfts() {
    return [...(this.totalWearableNfts || [])].filter((item: any) => item.attached)
  }

  @computed get filteredWearableNfts() {
    let wearableNftOwned = [...(this.totalWearableNfts || [])]
    if (this.selectedWearableTypeFilter !== 'all')
      wearableNftOwned = wearableNftOwned.filter((item) => item.type == this.selectedWearableTypeFilter)

    if (this.selectedWearableStatusFilter != undefined || this.selectedWearableTypeFilter != 'all') {
      const isAttached = this.selectedWearableStatusFilter == 'actived'
      wearableNftOwned = wearableNftOwned.filter((item: any) => {
        return isAttached ? item.attached : !item.attached
      })
    }
    return orderBy(wearableNftOwned, [this.selectedWearableNftSort.field], [this.selectedWearableNftSort.order])
  }

  @computed get builtMysteryBoxOwndedInfo() {
    const image = {
      url: 'https://algolaunch.s3.ap-northeast-2.amazonaws.com/re_box_2_1_46dff487ee.png',
    }
    const mysteryBoxOwned = [...this.mysteryBoxOwned]?.map((item) => {
      return { assetId: item, image }
    })
    return orderBy(mysteryBoxOwned, [this.selectedMysteryBoxSort.field], [this.selectedMysteryBoxSort.order])
  }

  //
}














































import { Observer } from 'mobx-vue'
import { Component, Provide, Vue, Watch } from 'vue-property-decorator'
import { ProfileNftViewModel } from '@/modules/profile/viewmodels/profile-nft-viewmodel'
@Observer
@Component({
  components: {
    'popo-tab': () => import('@/modules/profile/components/nft-tabs/popo-tab.vue'),
    'wearable-tab': () => import('@/modules/profile/components/nft-tabs/wearable-tab.vue'),
    'mystery-box-tab': () => import('@/modules/profile/components/nft-tabs/mystery-box-tab.vue'),
    'popo-management': () => import('@/modules/profile/components/popo-management.vue'),
  },
})
export default class extends Vue {
  @Provide() vm = new ProfileNftViewModel()
  tab = null

  changeTab(index) {
    this.tab = index
  }
  @Watch('$route.query.nft_tab', { immediate: true }) nftTabChanged(tab) {
    console.log('Tab', tab)
    if (tab === 'mystery-box') {
      this.changeTab(2)
    } else if (tab === 'popo') {
      this.changeTab(0)
    } else if (tab === 'wearable') {
      this.changeTab(1)
    }
    console.log('tab', tab)
  }
}
